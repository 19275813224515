import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import WebbeeLogo from "svg/logos/Webbee";
import { Alert, Snackbar } from "@mui/material";
import supabase from "common/supabase";
import Dialog from "common/Dialog";
import { sendEvent } from "services/analytics";

type Props = {
  themeMode: any;
  themeToggler: any;
  onSidebarOpen: any;
};

const Topbar = ({ themeMode, themeToggler, onSidebarOpen }: Props) => {
  /**
   * State
   */

  const [isOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  /**
   * Handlers
   */

  const onDialogSubmit = async (data: { email: string }) => {
    const { error } = await supabase
      .from("User")
      .insert({ email: data.email })
      .select();

    if (error) {
      console.error("error", error);
      return;
    }

    setAlertMessage("Email cadastrado com sucesso!");

    setIsOpen(true);
    setIsDialogOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsDialogOpen(false);
  };

  const handleButtonClick = () => {
    // Send the event to Google Analytics
    sendEvent({
      category: "topbar-register-button",
      action: "click",
      label: "Inscreva-se Agora",
    });

    // Redirect to the desired URL
    window.location.href = "https://app.wallex.com.br";
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <Dialog
        open={isDialogOpen}
        onSubmit={onDialogSubmit}
        onClose={handleClose}
      />
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box display={"flex"} alignItems={"center"}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          {/* <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon />
          </IconButton> */}
        </Box>
        <Box
          display={"flex"}
          alignItems="baseline"
          component="a"
          href="/"
          title="webbee"
          height={{ xs: 50, md: 50 }}
          width={50}
        >
          <WebbeeLogo height={"100%"} width={"100%"} />
        </Box>
      </Box>
      <Box display="flex" alignItems={"center"}>
        <Box>
          {/* <IconButton
            onClick={() => themeToggler()}
            aria-label="Dark mode toggler"
            color={themeMode === "light" ? "primary" : "secondary"}
          >
            {themeMode === "light" ? (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                />
              </svg>
            ) : (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            )}
          </IconButton> */}
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
          {/* <Box>
            <Link underline="none" component="a" href="/" color="textPrimary">
              Home
            </Link>
          </Box>
          <Box marginX={2}>
            <Link
              underline="none"
              component="a"
              href="/docs-introduction"
              color="textPrimary"
            >
              Documentation
            </Link>
          </Box> */}
          <Box>
            <Button
              variant="contained"
              color="primary"
              component="a"
              onClick={handleButtonClick}
              size="large"
            >
              Inscreva-se Agora
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
